// Card.js
import React, { useMemo, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import CardBack from "./CardBack";

const MotionBox = motion(Box);

function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Card = ({ rank, suit, colorScheme }) => {
  const isRed = useMemo(() => suit === "♥" || suit === "♦", [suit]);
  const cardColor = useMemo(() => (isRed ? "red.500" : "black.500"), [isRed]);
  const isCardBack = rank === "?" && suit === "?";
  const flipControls = useAnimation();

  const addVariants = {
    initial: { scale: 0.8, opacity: 0, x: 50 },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
    exit: {
      scale: 0.8,
      opacity: 0,
      x: -50,
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  const flipVariants = {
    faceDown: {
      rotateY: 0,
      rotateX: 2,
      scaleX: 1,
      boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
      transition: {
        rotateY: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        rotateX: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        scaleX: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        boxShadow: { duration: 0.6, ease: "easeInOut" },
      },
    },
    faceUp: {
      rotateY: 180,
      rotateX: 5, // Slight X-axis rotation for 3D effect
      scaleX: 1,
      boxShadow: "0px 10px 20px rgba(0,0,0,0.4)",
      transition: {
        rotateY: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        rotateX: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        scaleX: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        boxShadow: { duration: 0.6, ease: "easeInOut" },
      },
    },
  };

  const prevRank = usePrevious(rank);
  const prevSuit = usePrevious(suit);

  const [visibleRank, setVisibleRank] = useState(rank);
  const [visibleSuit, setVisibleSuit] = useState(suit);

  const [isFlipping, setIsFlipping] = useState(false);

  useEffect(() => {
    if (
      prevRank === "?" &&
      prevSuit === "?" &&
      (rank !== "?" || suit !== "?") &&
      !isFlipping
    ) {
      const performFlip = async () => {
        setIsFlipping(true);
        // Collapse the card horizontally (scaleX to 0)
        await flipControls.start({
          scaleX: 0,
          transition: { duration: 0.3, ease: "easeInOut" },
        });
        setVisibleRank(rank);
        setVisibleSuit(suit);
        await flipControls.start({
          scaleX: 1,
          transition: { duration: 0.3, ease: "easeInOut" },
        });
        setIsFlipping(false);
      };
      performFlip();
    } else {
      if (isCardBack) {
        setVisibleRank("?");
        setVisibleSuit("?");
        flipControls.start("faceDown");
      } else {
        setVisibleRank(rank);
        setVisibleSuit(suit);
        flipControls.start("faceUp");
      }
    }
  }, [rank, suit, prevRank, prevSuit, flipControls, isFlipping, isCardBack]);

  return (
    <MotionBox
      w="100px"
      h="150px"
      perspective="1500px"
      position="relative"
      borderRadius="10px"
      variants={addVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      m="0 5px"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <MotionBox
        position="absolute"
        w="100%"
        h="100%"
        style={{ transformStyle: "preserve-3d" }}
        variants={flipVariants}
        animate={flipControls}
        borderRadius="10px"
      >
        <Box
          position="absolute"
          w="100%"
          h="100%"
          style={{ backfaceVisibility: "hidden" }}
          transform="rotateY(180deg)"
          bg="#F9F6EE"
          overflow="hidden"
          borderRadius="10px"
        >
          {!isCardBack && (
            <>
              <Box
                position="absolute"
                top="5px"
                left="5px"
                textAlign="center"
                overflow="hidden"
                borderRadius="10px"
              >
                <Text fontSize="1.2rem" color={cardColor} lineHeight="1">
                  {visibleRank}
                </Text>
                <Text fontSize="1.2rem" color={cardColor} lineHeight="1">
                  {visibleSuit}
                </Text>
              </Box>
              <Text
                fontSize="4rem"
                color={cardColor}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                {visibleSuit}
              </Text>
              <Box
                position="absolute"
                bottom="5px"
                right="5px"
                textAlign="center"
                transform="rotate(180deg)"
              >
                <Text fontSize="1.2rem" color={cardColor} lineHeight="1">
                  {visibleRank}
                </Text>
                <Text fontSize="1.2rem" color={cardColor} lineHeight="1">
                  {visibleSuit}
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          style={{ backfaceVisibility: "hidden" }}
          borderRadius="10px"
          transform="rotateY(0deg)"
        >
          <CardBack colorScheme={colorScheme} />
        </Box>
      </MotionBox>
    </MotionBox>
  );
};

export default Card;

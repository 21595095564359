// components/PlayerHand.js
import React from "react";
import { VStack } from "@chakra-ui/react";
import Hand from "./Hand";
import GameActions from "./GameActions";

const PlayerHand = ({
  hand,
  index,
  colorScheme,
  gameData,
  showLabel,
  showGameActions,
  isLoading,
  handleAction,
  canDoubleDown,
  canSplit,
  loadingAction,
  showGameStatus,
  showPayoutMessage,
}) => {
  return (
    <VStack width="100%" spacing={4}>
      <Hand
        hand={hand}
        colorScheme={colorScheme}
        gameStarted={gameData.gameStarted}
        betAmount={
          index < gameData.betAmounts.length && gameData.betAmounts[index] > 0
            ? gameData.betAmounts[index]
            : null
        }
        handStatus={
          index < gameData.handStatuses.length && gameData.gameSettled
            ? gameData.handStatuses[index]
            : null
        }
        payout={
          index < gameData.payouts.length && gameData.gameSettled
            ? gameData.payouts[index]
            : null
        }
        label={
          showLabel && gameData.playerHands.length > 1
            ? `Hand ${index + 1}`
            : null
        }
        gameLoaded={gameData.gameLoaded}
        showGameStatus={showGameStatus}
        showPayoutMessage={showPayoutMessage}
      />
      <GameActions
        isLoading={isLoading}
        hit={handleAction("hit")}
        stand={handleAction("stand")}
        doubleDown={handleAction("doubleDown")}
        split={handleAction("split")}
        canDoubleDown={canDoubleDown}
        canSplit={canSplit}
        gameStarted={gameData.gameStarted}
        gameSettled={gameData.gameSettled}
        showGameActions={showGameActions}
        currentHand={index === gameData.currentHandIndex}
        loadingAction={loadingAction}
      />
    </VStack>
  );
};

export default PlayerHand;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConnectKitProvider } from "connectkit";
import { ChakraProvider } from "@chakra-ui/react";
import { Chain } from "./Chain";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Chain>
        <ConnectKitProvider>
          <App />
        </ConnectKitProvider>
      </Chain>
    </ChakraProvider>
  </React.StrictMode>
);

// src/App.js
import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useAlerts } from "./Alerts";
import { VStack, Box } from "@chakra-ui/react";
import Instructions from "./Instructions";
import Blackjack from "./Blackjack";

function App() {
  const { AlertsComponent, addAlert } = useAlerts();
  const [isBlackjackFullScreen, setIsBlackjackFullScreen] = useState(false);

  const toggleFullscreen = () => {
    setIsBlackjackFullScreen((prev) => !prev);
  };

  useEffect(() => {
    if (isBlackjackFullScreen) {
      document.body.classList.add("fullscreen");
    } else {
      document.body.classList.remove("fullscreen");
    }
    setTimeout(() => {
      const height = document.body.offsetHeight;
      console.log(`Reflow: ${height}`);
    }, 100);
  }, [isBlackjackFullScreen]);

  return (
    <Box
      boxShadow="lg"
      backgroundColor={isBlackjackFullScreen ? "green.700" : "gray.100"}
      width="100%"
      minHeight="100vh"
      p={0}
      m={0}
      z={1501}
    >
      {!isBlackjackFullScreen && <Header />}
      <VStack spacing={4} p={3}>
        {!isBlackjackFullScreen && <Instructions />}
        {!isBlackjackFullScreen && <AlertsComponent />}
        <Blackjack
          addAlert={addAlert}
          fullscreen={isBlackjackFullScreen}
          toggleFullscreen={toggleFullscreen}
        />
      </VStack>
    </Box>
  );
}

export default App;

import React from "react";
import { Box } from "@chakra-ui/react";

const CardBack = ({ colorScheme }) => {
  const backgroundColor =
    colorScheme === "blue"
      ? "linear-gradient(135deg, #3182CE 0%, #2B6CB0 100%)"
      : "linear-gradient(135deg, #E53E3E 0%, #C53030 100%)";
  const patternColor = "rgba(255, 255, 255, 0.2)";

  return (
    <Box
      border="2px solid"
      borderColor={colorScheme + ".800"}
      overflow="hidden"
      borderRadius="10px"
      w="100%"
      h="100%"
      position="relative"
      background={backgroundColor}
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background={`repeating-linear-gradient(
          45deg,
          ${patternColor},
          ${patternColor} 10px,
          rgba(0, 0, 0, 0) 10px,
          rgba(0, 0, 0, 0) 20px
        )`}
      />
    </Box>
  );
};

export default CardBack;

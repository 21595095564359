// src/Blackjack.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import { VStack, Box, Flex } from "@chakra-ui/react";
import { ethers } from "ethers";
import Confetti from "react-confetti";

import useViewportHeight from "./hooks/useViewportHeight";
import useColorScheme from "./hooks/useColorScheme";
import useFetchGameData from "./hooks/useFetchGameData";
import useCardAnimation from "./hooks/useCardAnimation";

import { fetchBalance, executeContractFunction } from "./utils/ethersUtils";
import { blackjackAddress, provider } from "./constants";
import calculateHandValues from "./utils/calculateHandValues";

import DealerHand from "./components/DealerHand";
import PlayerHands from "./components/PlayerHands";
import Betting from "./components/Betting";
import StatusDisplay from "./components/StatusDisplay";
import UIControls from "./components/UIControls";
import AccountSelector from "./components/AccountSelector";
import { accounts } from "./constants/accounts";

const Blackjack = ({ addAlert, fullscreen, toggleFullscreen }) => {
  const viewportHeight = useViewportHeight();
  const dynamicHeight = fullscreen ? `${viewportHeight}px` : "730px";

  const [playerAddress, setPlayerAddress] = useState(() => {
    const storedAddress = localStorage.getItem("playerAddress");
    if (storedAddress) {
      return storedAddress;
    } else {
      const randomAccount =
        accounts[Math.floor(Math.random() * accounts.length)];
      localStorage.setItem("playerAddress", randomAccount.address);
      return randomAccount.address;
    }
  });

  const [dealerBankroll, setDealerBankroll] = useState("0");
  const [playerBankroll, setPlayerBankroll] = useState("0");
  const [showLabel, setShowLabel] = useState(false);
  const [showGameActions, setShowGameActions] = useState(false);
  const [showBetButton, setShowBetButton] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(false);
  const [betAmount, setBetAmount] = useState(
    () => localStorage.getItem("betAmount") || "1"
  );
  const [loadingAction, setLoadingAction] = useState("");
  const [showGameStatus, setShowGameStatus] = useState(false);
  const [showPayoutMessage, setShowPayoutMessage] = useState(false);
  const { gameData, setGameData, fetchGameData, isLoading, setIsLoading } =
    useFetchGameData(playerAddress, addAlert);
  const [colorScheme, setNewColorScheme] = useColorScheme();

  const initializeCardShown = () => {
    return {
      player: gameData.playerHands.map((hand) => hand.map(() => false)),
      dealer: gameData.dealerHand.map(() => false),
    };
  };

  const [cardShown, setCardShown] = useState(
    () => JSON.parse(localStorage.getItem("cardShown")) || initializeCardShown()
  );
  const [lastFlippedCardIndex, setLastFlippedCardIndex] = useState({});
  const cardShownRef = useRef(cardShown);
  const lastFlippedCardIndexRef = useRef(lastFlippedCardIndex);

  useEffect(() => {
    cardShownRef.current = cardShown;
  }, [cardShown]);

  useEffect(() => {
    lastFlippedCardIndexRef.current = lastFlippedCardIndex;
  }, [lastFlippedCardIndex]);

  const [animatedPlayerHands, setAnimatedPlayerHands] = useState([]);
  const [animatedDealerHand, setAnimatedDealerHand] = useState(() =>
    gameData.dealerHand.map(() => "??")
  );

  const { flipHandsSequentially } = useCardAnimation(
    gameData,
    cardShown,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setLastFlippedCardIndex
  );

  const updateBalances = useCallback(async () => {
    try {
      const [dealerBalance, playerBalance] = await Promise.all([
        fetchBalance(blackjackAddress, provider),
        fetchBalance(playerAddress, provider),
      ]);
      setDealerBankroll(dealerBalance);
      setPlayerBankroll(playerBalance);
    } catch (error) {
      console.error("Error updating balances:", error);
      addAlert({
        status: "error",
        title: "Balance Update Failed",
        description: "Unable to update balances at this time.",
      });
    }
  }, [playerAddress, addAlert]);

  // NEW: Update balances on component mount
  useEffect(() => {
    updateBalances();
  }, [updateBalances]);

  const addPlaceholderCardToHand = (handIndex) => {
    setAnimatedPlayerHands((prev) => {
      const updatedHands = [...prev];
      if (updatedHands[handIndex]) {
        updatedHands[handIndex] = [...updatedHands[handIndex], "??"];
      } else {
        console.log("Hand index setAnimatedPlayerHands not found:", handIndex);
        updatedHands[handIndex] = ["??"];
      }
      return updatedHands;
    });

    setCardShown((prevCardShown) => {
      const updatedCardShown = { ...prevCardShown };
      updatedCardShown.player = [...updatedCardShown.player];
      if (updatedCardShown.player[handIndex]) {
        updatedCardShown.player[handIndex] = [
          ...updatedCardShown.player[handIndex],
          false,
        ];
      } else {
        console.log("Hand index setCardShown not found:", handIndex);
        updatedCardShown.player[handIndex] = [false];
      }
      return updatedCardShown;
    });

    setLastFlippedCardIndex((prevLastFlippedCardIndex) => {
      const updatedLastFlippedCardIndex = { ...prevLastFlippedCardIndex };
      updatedLastFlippedCardIndex[handIndex] =
        (updatedLastFlippedCardIndex[handIndex] || 0) + 1;
      console.log("Hand index setLastFlippedCardIndex not found:", handIndex);
      return updatedLastFlippedCardIndex;
    });
  };

  useEffect(() => {
    if (playerAddress) {
      fetchGameData().then(() => {
        setCardShown(initializeCardShown());
        setAnimatedPlayerHands(
          gameData.playerHands.map((hand) => hand.map(() => "??"))
        );
      });
    }
    // eslint-disable-next-line
  }, [playerAddress, fetchGameData]);

  const gameFlowLock = useRef(false);
  const delay = 250;

  useEffect(() => {
    if (gameData.gameStarted && !gameFlowLock.current) {
      gameFlowLock.current = true;
      //console.log("Starting game flow (locking)");
      const handleGameFlow = async () => {
        try {
          await flipHandsSequentially("player");
          await flipHandsSequentially("dealer");
          await new Promise((resolve) => setTimeout(resolve, delay));
          if (!gameData.gameSettled) {
            setShowGameActions(true);
            gameFlowLock.current = false;
            return;
          }
          setShowGameStatus(true);
          await new Promise((resolve) => setTimeout(resolve, delay));
          setShowPayoutMessage(true);
          updateBalances();
          await new Promise((resolve) => setTimeout(resolve, delay));
          setShowBetButton(true);
        } catch (error) {
          console.error("Error during game flow:", error);
        }
        //console.log("Ending game flow (unlocking)");
        gameFlowLock.current = false;
      };
      handleGameFlow();
    }
  }, [gameData, flipHandsSequentially, updateBalances, setIsLoading]);

  useEffect(() => {
    localStorage.setItem("cardShown", JSON.stringify(cardShown));
  }, [cardShown]);

  useEffect(() => {
    if (gameData.handStatuses.includes("BLACKJACK!") && !confettiRecycle) {
      setConfettiRunning(true);
      setConfettiRecycle(true);
    }
  }, [gameData.handStatuses, confettiRecycle]);

  useEffect(() => {
    localStorage.setItem("betAmount", betAmount);
  }, [betAmount]);

  const handleBetAmountChange = (e) => {
    setBetAmount(e.target.value);
  };

  const startNewGame = async () => {
    setLoadingAction("Placing Bet");
    if (parseFloat(betAmount) <= 0 || isNaN(betAmount)) {
      addAlert({
        status: "error",
        title: "Invalid Bet",
        description: "Please enter a valid bet amount greater than 0.",
      });
      setLoadingAction("");
      await new Promise((resolve) => setTimeout(resolve, delay));
      updateBalances();
      return;
    }

    clearBoard();
    setIsLoading(true);

    await executeContractFunction({
      provider: provider,
      playerAddress,
      setIsLoading,
      fetchGameData,
      addAlert,
      updateBalances,
      functionName: "startGame",
      value: ethers.parseEther(betAmount),
      setLoadingAction,
    });
  };

  const clearBoard = () => {
    setConfettiRecycle(false);

    const initialPlayerHands = [["??", "??"]];
    const initialDealerHand = ["??", "??"];

    setGameData({
      playerHands: initialPlayerHands,
      dealerHand: initialDealerHand,
      gameStarted: false,
      gameSettled: false,
      currentHandIndex: 0,
      betAmounts: [],
      handStatuses: [],
      payouts: [],
    });
    setAnimatedPlayerHands(
      initialPlayerHands.map((hand) => hand.map(() => "??"))
    );
    setAnimatedDealerHand(initialDealerHand.map(() => "??"));
    setCardShown({
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    });
    cardShownRef.current = {
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    };
    lastFlippedCardIndexRef.current = {};

    resetShowStates();

    localStorage.removeItem("cardShown");
    setNewColorScheme();
    setAnimatedPlayerHands(
      initialPlayerHands.map((hand) => hand.map(() => "??"))
    );

    // NEW: Ensure balances are updated after clearing the board
    updateBalances();
  };

  const resetShowStates = () => {
    setShowGameStatus(false);
    setShowPayoutMessage(false);
    updateBalances();
    setShowLabel(false);
    setShowGameActions(false);
    setShowBetButton(false);
  };

  const handleAction = (actionFunction) => async () => {
    resetShowStates();
    let actionMessage = "";
    switch (actionFunction) {
      case "hit":
        actionMessage = "Hitting";
        break;
      case "stand":
        actionMessage = "Standing";
        break;
      case "doubleDown":
        actionMessage = "Doubling Down";
        break;
      case "split":
        actionMessage = "Splitting";
        break;
      default:
        actionMessage = "Loading";
    }
    setLoadingAction(actionMessage);

    if (actionFunction === "hit") {
      addPlaceholderCardToHand(gameData.currentHandIndex);
    }

    if (actionFunction === "doubleDown") {
      addPlaceholderCardToHand(gameData.currentHandIndex);
    }

    if (actionFunction === "split") {
      const [card1, card2] = gameData.playerHands[gameData.currentHandIndex];
      setGameData((prevGameData) => {
        const updatedPlayerHands = [...prevGameData.playerHands];
        updatedPlayerHands[gameData.currentHandIndex] = [card1, "??"];
        updatedPlayerHands.splice(gameData.currentHandIndex + 1, 0, [
          card2,
          "??",
        ]);

        return {
          ...prevGameData,
          playerHands: updatedPlayerHands,
        };
      });

      setAnimatedPlayerHands((prevHands) => {
        const updatedHands = [...prevHands];
        if (updatedHands[gameData.currentHandIndex]) {
          updatedHands[gameData.currentHandIndex] = [card1, "??"];
          updatedHands.splice(gameData.currentHandIndex + 1, 0, [card2, "??"]);
        } else {
          updatedHands.push([card2, "??"]);
        }
        return updatedHands;
      });

      setCardShown((prevCardShown) => {
        const updatedCardShown = { ...prevCardShown };
        updatedCardShown.player = [...updatedCardShown.player];
        if (updatedCardShown.player[gameData.currentHandIndex]) {
          updatedCardShown.player[gameData.currentHandIndex] = [true, false];
          updatedCardShown.player.splice(gameData.currentHandIndex + 1, 0, [
            true,
            false,
          ]);
        } else {
          updatedCardShown.player.push([true, false]);
        }
        return updatedCardShown;
      });

      setLastFlippedCardIndex((prevLastFlippedCardIndex) => {
        const updatedLastFlippedCardIndex = { ...prevLastFlippedCardIndex };
        updatedLastFlippedCardIndex[gameData.currentHandIndex] = 0;
        updatedLastFlippedCardIndex[gameData.currentHandIndex + 1] = 0;
        return updatedLastFlippedCardIndex;
      });
    }

    let value = null;
    if (actionFunction === "doubleDown" || actionFunction === "split") {
      const currentBetAmount = gameData.betAmounts[gameData.currentHandIndex];
      value = ethers.parseEther(currentBetAmount);
    }

    await executeContractFunction({
      provider: provider,
      playerAddress,
      setIsLoading,
      fetchGameData,
      addAlert,
      updateBalances,
      functionName: actionFunction,
      functionArgs: [],
      value: value,
      setLoadingAction,
    });

    await fetchGameData();

    if (["hit", "doubleDown", "split"].includes(actionFunction)) {
      setCardShown((prevCardShown) => {
        const updatedCardShown = { ...prevCardShown };
        updatedCardShown.player = gameData.playerHands.map(
          (hand, handIndex) => {
            return hand.map((card, cardIndex) => {
              if (
                prevCardShown.player[handIndex] &&
                prevCardShown.player[handIndex][cardIndex] !== undefined
              ) {
                return prevCardShown.player[handIndex][cardIndex];
              }
              return false;
            });
          }
        );
        return updatedCardShown;
      });

      await flipHandsSequentially("player");
    }

    setLoadingAction("");
  };

  const currentHand = gameData.playerHands[gameData.currentHandIndex];
  const possibleHandValues = calculateHandValues(currentHand);

  const getCardValue = (card) => {
    return card.length === 3 ? card.slice(0, 2) : card.slice(0, 1);
  };

  const canDoubleDown =
    currentHand.length === 2 &&
    possibleHandValues.some((val) => [9, 10, 11].includes(val)) &&
    !gameData.gameSettled;

  const canSplit =
    currentHand.length === 2 &&
    getCardValue(currentHand[0]) === getCardValue(currentHand[1]);

  const handleAccountChange = (newAddress) => {
    clearBoard();
    setPlayerAddress(newAddress);
    localStorage.setItem("playerAddress", newAddress);
    fetchGameData();
  };

  const [overflowing, setOverflowing] = useState(false);

  const flexRef = useRef(null);

  const checkOverflow = useCallback(() => {
    const flexElement = flexRef.current;
    if (flexElement) {
      const isOverflowing = flexElement.scrollWidth > flexElement.clientWidth;
      setOverflowing(isOverflowing);
    }
  }, []);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [checkOverflow]);

  useEffect(() => {
    checkOverflow();
  }, [gameData, checkOverflow]);

  return (
    <Flex
      ref={flexRef}
      p={3}
      w="100%"
      h={fullscreen ? dynamicHeight : "730px"}
      top={fullscreen ? 0 : "auto"}
      bg="green.700"
      borderColor="green.900"
      borderRadius={fullscreen ? "0" : "10px"}
      borderWidth={fullscreen ? 0 : 3}
      overflowX={overflowing ? "auto" : "hidden"}
      overflowY={overflowing ? "auto" : "hidden"}
      justifyContent={overflowing ? "flex-start" : "center"}
    >
      <UIControls fullscreen={fullscreen} toggleFullscreen={toggleFullscreen} />
      <Confetti run={confettiRunning} recycle={confettiRecycle} />
      <Box w="max-content" minW="100%">
        <VStack w="100%">
          <Box h="220px">
            <DealerHand
              animatedDealerHand={animatedDealerHand}
              colorScheme={colorScheme}
              gameStarted={gameData.gameStarted}
              ethAmount={dealerBankroll}
            />
          </Box>
          <Box h="310px">
            <PlayerHands
              animatedPlayerHands={animatedPlayerHands}
              colorScheme={colorScheme}
              gameData={gameData}
              showLabel={showLabel}
              showGameActions={showGameActions}
              isLoading={isLoading}
              handleAction={handleAction}
              canDoubleDown={canDoubleDown}
              canSplit={canSplit}
              loadingAction={loadingAction}
              showGameStatus={showGameStatus}
              showPayoutMessage={showPayoutMessage}
            />
          </Box>
          <Box h="50px">
            {(!gameData.gameStarted ||
              (gameData.gameSettled && showBetButton)) && (
              <Betting
                isLoading={isLoading}
                betAmount={betAmount}
                handleBetAmountChange={handleBetAmountChange}
                startNewGame={startNewGame}
                loadingAction={loadingAction}
              />
            )}
          </Box>
          <Box h="40px">
            <StatusDisplay playerBankroll={playerBankroll} />
          </Box>
          <Box h="40px">
            <AccountSelector
              currentAccount={playerAddress}
              onAccountChange={handleAccountChange}
            />
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Blackjack;

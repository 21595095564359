// src/components/StatusDisplay.js
import React from "react";
import { Text, Box } from "@chakra-ui/react";

const StatusDisplay = ({ playerBankroll }) => {
  return (
    <Box>
      <Text color="#F9F6EE" fontSize="lg" pt={1}>
        Player bankroll {parseFloat(playerBankroll).toFixed(2)}
      </Text>
    </Box>
  );
};

export default StatusDisplay;

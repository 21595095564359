// src/hooks/useViewportHeight.js
import { useState, useEffect } from "react";

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(
    document.documentElement.clientHeight || window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(
        document.documentElement.clientHeight || window.innerHeight
      );
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Initial setting
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return viewportHeight;
};

export default useViewportHeight;

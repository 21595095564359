// src/hooks/useFetchGameData.js
import { useState, useCallback } from "react";
import { ethers } from "ethers";
import { blackjackAddress, provider } from "../constants";
import BlackjackABI from "../BlackjackABI.json";

const useFetchGameData = (address, addAlert) => {
  const [gameData, setGameData] = useState({
    playerHands: [["??", "??"]],
    dealerHand: ["??", "??"],
    betAmounts: [],
    handStatuses: [],
    payouts: [],
    gameStarted: false,
    gameSettled: false,
    currentHandIndex: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const formatCard = (card) => {
    const rankMapping = [
      "A",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "J",
      "Q",
      "K",
    ];
    const suitMapping = ["♠", "♥", "♦", "♣"];

    const rankNumber = Number(card.rank);
    const suitNumber = Number(card.suit);

    if (rankNumber === 0) {
      return "??";
    }

    const rank = rankMapping[rankNumber - 1] || "??";
    const suit = suitMapping[suitNumber] || "??";

    return `${rank}${suit}`;
  };

  const fetchGameData = useCallback(async () => {
    if (!address) return;
    const contract = new ethers.Contract(
      blackjackAddress,
      BlackjackABI,
      provider
    );

    try {
      const gameStatus = await contract.gameStatus(address);

      const playerHands = gameStatus.playerHands?.length
        ? gameStatus.playerHands.map((handStruct) =>
            handStruct.cards.map(formatCard)
          )
        : [["??", "??"]];

      const dealerHand = gameStatus.dealerHand.cards?.length
        ? gameStatus.dealerHand.cards.map(formatCard)
        : ["??", "??"];

      const betAmounts =
        gameStatus.playerHands?.map((hand) => ethers.formatEther(hand.bet)) ||
        [];
      const payouts =
        gameStatus.playerHands?.map((hand) =>
          ethers.formatEther(hand.payout)
        ) || [];

      const resultMapping = [
        "NotSet",
        "BLACKJACK!",
        "Push",
        "Player Won",
        "Dealer Won",
        "Busted",
      ];
      const handStatuses =
        gameStatus.playerHands?.map((hand) => resultMapping[hand.result]) || [];

      const newGameData = {
        playerHands,
        dealerHand,
        betAmounts,
        handStatuses,
        payouts,
        gameStarted: playerHands.some((hand) =>
          hand.some((card) => card !== "??")
        ),
        gameSettled: gameStatus.settled,
        currentHandIndex: Number(gameStatus.currentHandIndex),
      };

      setGameData(newGameData);
    } catch (err) {
      addAlert({
        status: "error",
        title: "Error fetching game data",
        description: `Error fetching game data: ${err.message}`,
      });
      console.error(err);
      setGameData((prev) => ({ ...prev, gameStarted: false }));
    }
    // eslint-disable-next-line
  }, [address, addAlert]);

  return { gameData, setGameData, fetchGameData, isLoading, setIsLoading };
};

export default useFetchGameData;

import React from "react";
import {
  HStack,
  Input,
  Button,
  Icon,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";

const Betting = ({
  isLoading,
  betAmount,
  handleBetAmountChange,
  startNewGame,
  loadingAction,
  dealerAmount, // Assuming dealerAmount is passed as a prop
}) => {
  return (
    <HStack>
      {isLoading ? (
        <HStack>
          <Spinner size="lg" color="#F9F6EE" />
          <Text fontSize="xl" color="#F9F6EE">
            {loadingAction}...
          </Text>
        </HStack>
      ) : (
        <>
          <Tooltip
            label={`Max bet is 10% of the dealer's bankroll.`}
            aria-label="Betting limit tooltip"
            hasArrow
            bg="gray.700"
            color="white"
          >
            <Input
              placeholder="Bet Amount"
              value={betAmount}
              onChange={handleBetAmountChange}
              size="md"
              variant="filled"
              w="120px"
              fontSize="1.2em"
              boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
            />
          </Tooltip>
          <Button
            onClick={startNewGame}
            colorScheme="blue"
            size="md"
            leftIcon={<Icon as={FaEthereum} />}
            _hover={{ transform: "scale(1.05)" }}
            boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
          >
            Place Bet
          </Button>
        </>
      )}
    </HStack>
  );
};

export default Betting;

// src/components/AccountSelector.js
import React from "react";
import { Select } from "@chakra-ui/react";
import { accounts } from "../constants/accounts";

const AccountSelector = ({ currentAccount, onAccountChange }) => {
  const handleAccountChange = (event) => {
    const selectedAddress = event.target.value;
    onAccountChange(selectedAddress);
  };

  return (
    <Select
      color="white"
      onChange={handleAccountChange}
      value={currentAccount}
      width="160px"
    >
      {accounts.map((account) => (
        <option key={account.address} value={account.address}>
          {account.label}
        </option>
      ))}
    </Select>
  );
};

export default AccountSelector;

// components/PlayerHands.js
import React from "react";
import { HStack } from "@chakra-ui/react";
import PlayerHand from "./PlayerHand";

const PlayerHands = ({
  animatedPlayerHands,
  colorScheme,
  gameData,
  showLabel,
  showGameActions,
  isLoading,
  handleAction,
  canDoubleDown,
  canSplit,
  loadingAction,
  showGameStatus,
  showPayoutMessage,
}) => {
  return (
    <HStack spacing={8}>
      {animatedPlayerHands.map((hand, index) => (
        <PlayerHand
          key={index}
          hand={hand}
          index={index}
          colorScheme={colorScheme}
          gameData={gameData}
          showLabel={showLabel}
          showGameActions={showGameActions}
          isLoading={isLoading}
          handleAction={handleAction}
          canDoubleDown={canDoubleDown}
          canSplit={canSplit}
          loadingAction={loadingAction}
          showGameStatus={showGameStatus}
          showPayoutMessage={showPayoutMessage}
        />
      ))}
    </HStack>
  );
};

export default PlayerHands;

// src/components/UIControls.js
import React from "react";
import { IconButton } from "@chakra-ui/react";
import { FaCompress, FaExpand } from "react-icons/fa";

const UIControls = ({ fullscreen, toggleFullscreen }) => {
  return (
    <IconButton
      icon={fullscreen ? <FaCompress /> : <FaExpand />}
      onClick={toggleFullscreen}
      aria-label="Toggle Fullscreen"
      colorScheme="#F9F6EE"
      size="lg"
      position="absolute"
      right={7}
      _hover={{ transform: "scale(1.05)" }}
      cursor="pointer"
    />
  );
};

export default UIControls;

import React from "react";
import {
  Box,
  Link,
  OrderedList,
  Text,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ListItem,
  Divider,
  Heading,
  List,
  ListIcon,
} from "@chakra-ui/react";
import {
  ExternalLinkIcon,
  ArrowUpDownIcon,
  TimeIcon,
  LockIcon,
  ViewIcon,
} from "@chakra-ui/icons";

const Instructions = () => {
  return (
    <Accordion allowToggle pt={3}>
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="center">
            <Heading size="sm" color="#1f3c60">
              What is Polygon Blackjack?
            </Heading>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Heading size={"md"} mb={3} align="center">
            Blackjack on the{" "}
            <Link
              color="blue.500"
              href="https://polygon.technology/"
              isExternal
            >
              Polygon Blockchain
              <ExternalLinkIcon />
            </Link>
          </Heading>
          <Heading size="sm" mb={3} align="center">
            Transparent - Anonymous - Fair
          </Heading>
          <Divider my={3} />
          <List spacing={3}>
            <ListItem>
              <ListIcon as={TimeIcon} color="cyan.500" />
              <b>Single deck</b> Shuffled every hand.
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowUpDownIcon} color="green.500" />
              <b>Multiple Splits</b> Split aces as many times as you'd like.
              Double down on 9, 10 or 11 even after splitting.
            </ListItem>
            <ListItem>
              <ListIcon as={LockIcon} color="teal.500" />
              <b>No bank</b> Play directly against the{" "}
              <Link
                color="blue.500"
                href="https://soliditylang.org/"
                isExternal
              >
                Solidity
                <ExternalLinkIcon />{" "}
              </Link>{" "}
              smart contract.
            </ListItem>
            <ListItem>
              <ListIcon as={ViewIcon} color="blue.500" />
              <b>Open source</b> View the smart contract code on{" "}
              <Link color="blue.500" href="https://polygonscan.com/" isExternal>
                PolygonScan. <ExternalLinkIcon />{" "}
              </Link>
              (Coming soon)
            </ListItem>
          </List>
          <Divider my={3} />
          <Text mt={4} align="center">
            This is a temporary test environment, play for free!
          </Text>
        </AccordionPanel>
      </AccordionItem>
      {window.location.hostname === "polygonamoy.blockchainblackjack.com" && (
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="center">
              <Heading size="sm">What is polygonAmoy Testnet?</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading size="sm" mb={3}>
              Try it out for free on the polygonAmoy Testnet!
            </Heading>
            <OrderedList mb={3} pl={5}>
              <ListItem mb={2}>
                Connect your MetaMask wallet to get started.
              </ListItem>
              <ListItem mb={2}>
                Request free testnet polygon{" "}
                <Link
                  color="teal.600"
                  isExternal
                  href="https://polygonamoy-faucet.pk910.de/"
                >
                  polygonAmoy Faucet <ExternalLinkIcon />
                </Link>
                .
              </ListItem>
              <ListItem mb={2}>
                Place your bets and play Blackjack. Watch your wallet balance
                change as you win or lose.
              </ListItem>
              <ListItem mb={2}>
                When you're ready to cash out, click Withdraw and confirm the
                transaction.
              </ListItem>
              <ListItem mb={2}>
                All game actions and transactions can be viewed on Etherscan.
              </ListItem>
            </OrderedList>
            <Text mb={3}>
              <Link
                color="blue.600"
                href="https://polygonamoy.etherscan.io/address/0xYourTestnetContractAddress#code"
                isExternal
              >
                Explore the polygonAmoy smart contract on Etherscan.{" "}
                <ExternalLinkIcon />
              </Link>
            </Text>
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  );
};

export default Instructions;

// Hand.js
import React from "react";
import { Box, Text, HStack } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import Card from "./Card";

const MotionBox = motion(Box);

const Hand = ({
  hand = [],
  handStatus,
  handIndex,
  label,
  betAmount,
  ethAmount,
  payout,
  colorScheme,
  showGameStatus,
  showPayoutMessage,
}) => {
  const renderCards = () => {
    const requiredCards = 2;
    const cardsToRender = [...hand];
    while (cardsToRender.length < requiredCards) {
      cardsToRender.push("??");
    }

    return cardsToRender.map((card, index) => {
      const rank = card.slice(0, -1);
      const suit = card.slice(-1);

      // Use a consistent key for each card to maintain component identity
      const cardKey = `${handIndex}-${index}`;

      return (
        <MotionBox
          key={cardKey}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card rank={rank} suit={suit} colorScheme={colorScheme} />
        </MotionBox>
      );
    });
  };

  const bet = parseFloat(betAmount);
  const payoutAmount = parseFloat(payout);

  let payoutMessage = "";
  let payoutTextProps = {};

  if (!isNaN(bet) && !isNaN(payoutAmount)) {
    if (payoutAmount > bet) {
      payoutMessage = `YOU WON ${payout}!`;
      payoutTextProps = {
        fontSize: "2xl",
        bgGradient: "linear(to-l, #FFEA00, #FFFF00)",
        bgClip: "text",
        fontWeight: "extrabold",
        textShadow: "6px 6px 20px rgba(0, 0, 0, 0.5)",
        as: "b",
        p: 4,
        m: 4,
      };
    } else if (payoutAmount === bet) {
      payoutMessage = `${payout} returned`;
      payoutTextProps = {
        fontSize: "2xl",
        color: "#F9F6EE",
        as: "b",
      };
    }
  }

  return (
    <Box align="center">
      {label === "dealer" && (
        <Text fontSize="lg" color="#F9F6EE">
          Dealer has {parseFloat(ethAmount).toFixed(2)}
        </Text>
      )}
      {label !== "dealer" && (
        <Box h="40px">
          {handStatus && showGameStatus && (
            <Text fontSize="2xl" color="#F9F6EE" as="b">
              {handStatus}
            </Text>
          )}
        </Box>
      )}
      <HStack spacing={-50} justifyContent="center" m={5}>
        <AnimatePresence initial={false}>{renderCards()}</AnimatePresence>
      </HStack>
      {label !== "dealer" && (
        <Box>
          <Text fontSize="xl" pb={2} color="#F9F6EE">
            {betAmount ? `Bet ${betAmount}` : ""}
          </Text>
          {payoutMessage && showPayoutMessage && (
            <Text
              textShadow="0px 5px 15px rgba(0,0,0,0.3)"
              {...payoutTextProps}
            >
              {payoutMessage}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Hand;
